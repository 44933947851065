import React, { SetStateAction, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Image } from "react-bootstrap"
import { t } from "i18next"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { useTimer } from "react-timer-hook"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  clearQrId,
  copyEvent,
  getEvent,
  getEventByHash,
} from "../../redux/slices/events/eventsSlice"
import {
  getContactsOnEvent,
  getContactsOnEventMobile,
  setCurrentPage,
} from "../../redux/slices/contacts/contactsSlices"
import Preloader from "../../components/utils/Preloader/Preloader"
import {
  IconArrowDownFat,
  IconArrowLeft,
  IconArrowUpFat,
  IconContactDelete,
  IconContactsChange,
  IconLocation,
  IconSearch,
} from "../../components/utils/Icons/CustomIcons"
import GuestsSearch from "../../components/Event/GuestsSearch"
import Paginator from "../../components/utils/Pagination/Pagination"
import ActionsPopup from "../../components/Event/ActionsPopup"
import TestModal from "../../components/Event/TestModal/TestModal"
import EnterCodeModal from "../../components/Event/EnterCodeModal/EnterCodeModal"
import VerifyModal from "../../components/Event/VerifyModal/VerifyModal"
import AddGuest from "../../components/Event/AddGuest/AddGuest"
import EditGuest from "../../components/Event/EditGuest/EditGuest"
import AddInvites from "../../components/Event/AddInvites/AddInvites"
import { iFile } from "../../components/Contacts/types"
import SendModal from "../../components/Event/SendModal/SendModal"
import SendQRModal from "../../components/Event/SendQRModal/SendQRModal"
import SendInviteModal from "../../components/Event/SendInviteModal/SendInviteModal"
import DeleteEventModal from "../../components/Event/DeleteEventModal/DeleteEventModal"
import ImportGuest from "../../components/Event/ImportGuest/ImportGuest"
import GuestsListOpen from "@/components/Event/GuestsListOpen"
import CopyModal from "@/components/Event/CopyModal/CopyModal"
import GuestsFilterOpen from "@/components/Event/GuestFilterOpen"
import "react-toastify/dist/ReactToastify.css"

const EventPageOpen = () => {
  const { event_id } = useParams()
  const navigate = useNavigate()
  const { data_uploaded } = useAppSelector((state) => state.contacts)
  const { isLoading, open_hash, is_open_event } = useAppSelector(
    (state) => state.events,
  )
  const event = useAppSelector((state) => state.events.currentEvent)
  const { current_page, total_pages, limit } = useAppSelector(
    (state) => state.contacts,
  )
  const dispatch = useAppDispatch()
  const [show, setShow] = useState<boolean>(false)
  const [showSendQR, setShowSendQR] = useState<boolean>(false)
  const [showImport, setShowImport] = useState<boolean>(false)
  const [showCopy, setShowCopy] = useState<boolean>(false)
  const [addShow, setAddShow] = useState(false)
  const [editShow, setEditShow] = useState(false)
  const [changeGuest, setChangeGuest] = useState({})
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false)
  const [showNextStep, setShowNextStep] = useState<boolean>(false)
  const [showTestModal, setShowTestModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [showModalSendInvite, setShowModalSendInvite] = useState<boolean>(false)
  const [showModalAddInvites, setShowModalAddInvites] = useState<boolean>(false)
  const [checked, setChecked] = useState([])
  const [isDetailCollapsed, setIsDetailCollapsed] = useState(true)
  const [checkedFilter, setCheckedFilter] = useState("")
  const [allGuestsCheked, setAllGuestsChecked] = useState([])
  const [phone, setPhone] = useState()
  const [validation, setValidation] = useState([])
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 })
  const [styles, setStyles] = useState({})
  const [fileImport, setFileImport] = useState<iFile | null>(null)
  const [showModalSendInvitations, setShowModalSendInvitations] =
    useState<boolean>(false)
  const time = new Date()
  time.setSeconds(time.getSeconds() + 180)
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: time,
  })
  const [newGuest, setNewGuest] = useState({
    event_id: event_id,
    first_name: "",
    last_name: "",
    suffix: "",
    phone: "",
    email: "",
    quantity_members: 0,
    deleted_at: null,
  })
  const [filter, setFilter] = useState({
    sort: "",
    order: "",
    searchValue: "",
    statusNumber: "",
    reminderNumber: "",
    id: Number(event_id),
  })

  const notify = (text: string, color: boolean) => {
    color
      ? toast.error(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  useEffect(() => {
    dispatch(getEvent(Number(event_id))).then((res: any) => {
      open_hash !== null &&
        is_open_event === 1 &&
        dispatch(getEventByHash(open_hash))
    })
  }, [dispatch, event_id, open_hash, is_open_event])

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
    dispatch(getContactsOnEvent(filter))
  }

  const addPopupClose = () => {
    setAddShow(false)
  }

  const addInvitesClose = () => {
    setShowModalAddInvites(false)
  }

  const importPopupClose = () => {
    setShowImport(false)
  }

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  const handleImageLoad = (e: any) => {
    const { width, height } = e.target
    setImgDimensions({ width, height })
  }

  useEffect(() => {
    if (imgDimensions.width > 0 && imgDimensions.height > 0) {
      if (imgDimensions.height > imgDimensions.width) {
        setStyles({ height: "100%", width: "auto" })
      } else {
        setStyles({ width: "100%", height: "auto" })
      }
    }
  }, [imgDimensions])

  return (
    <>
      {isLoading && <Preloader />}
      {event && (
        <div className="event-page event-page__open">
          <div key={event.event_id} className="container">
            <div className="event-page__link">
              <Link
                className="d-flex align-items-center event-page__link-container"
                to={"/events"}
              >
                <IconArrowLeft />
                <span className="event-page__link-text">
                  Back to All events
                </span>
              </Link>
              <ToastContainer
                style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
              />
            </div>
            <div className="d-flex justify-content-between event-page__buttons">
              <h2 className="caption-two">{event.name}</h2>
              <div className="d-flex">
                <button
                  className="d-flex justify-content-center align-items-center event-page--btn"
                  onClick={() => {
                    navigate(`/event-edit/${event.event_id}`)
                    dispatch(clearQrId())
                  }}
                >
                  <IconContactsChange />
                </button>
                <button
                  className="d-flex justify-content-center align-items-center event-page--btn"
                  onClick={() => {
                    setShowModalDelete(!showModalDelete)
                  }}
                >
                  <IconContactDelete />
                </button>
              </div>
            </div>
            <div className="event-wrapper">
              <div className="event-container">
                <div className="d-flex event-image__container">
                  <div className="event-image__img">
                    <img
                      src={
                        event.pattern ? event.pattern : "/vector/no-foto.svg"
                      }
                      alt="Image"
                      style={styles}
                      className="img-fluid"
                      onLoad={handleImageLoad}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="d-flex mb-3 event-page__header">
                      <h2 className="caption-two event-page__title">
                        Detail Event
                      </h2>
                      <div className="d-flex event-page__invitation-buttons">
                        <button
                          className="btn btn-dark event-page__create-btn"
                          onClick={() => {
                            setShowCopy(true)
                            dispatch(copyEvent({ event_id: event_id }))
                          }}
                        >
                          Create Regular Event
                        </button>
                      </div>
                    </div>

                    <div className="event-page__card-wrapper">
                      <div className="event-page__card-container card-container-first">
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_all_contacts
                              ? event.count_all_contacts
                              : "0"}
                          </p>
                          <p className="event-page__card-name">All</p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_going
                              ? event.count_contacts_going
                              : "0"}
                          </p>
                          <p className="event-page__card-name">Going</p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_decline
                              ? event.count_contacts_decline
                              : "0"}
                          </p>
                          <p className="event-page__card-name">Decline</p>
                        </div>
                      </div>

                      <h2 className="caption-two event-page__visits-title">
                        Visits
                      </h2>

                      <div className="event-page__card-container card-container-visits">
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.open_event_page_unique_visits
                              ? event.open_event_page_unique_visits
                              : "0"}
                          </p>
                          <p className="event-page__card-name">Unique visits</p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.open_event_page_total_visits
                              ? event.open_event_page_total_visits
                              : "0"}
                          </p>
                          <p className="event-page__card-name">Total visits</p>
                        </div>
                      </div>
                    </div>
                    <div className="event-page__details">
                      <div
                        className="d-flex justify-content-between align-items-center event-page__details-header"
                        onClick={() => setIsDetailCollapsed(!isDetailCollapsed)} // Toggle on click
                        style={{ cursor: "pointer" }} // Show pointer cursor
                      >
                        <h2 className="event-page__details-title">
                          See More Details
                        </h2>
                        <span>
                          {isDetailCollapsed ? (
                            <IconArrowDownFat />
                          ) : (
                            <IconArrowUpFat />
                          )}
                        </span>
                      </div>
                      {!isDetailCollapsed && (
                        <div className="d-flex justify-content-between event-page__details-cards">
                          {(event.venue !== "" ||
                            event.address.address !== "") && (
                            <div className="d-flex event-page__details-card">
                              <IconLocation />
                              <div className="event-page__details-description">
                                <p className="event-page__details-caption">
                                  Location
                                </p>
                                {event.venue !== "" && (
                                  <h4 className="mb-1 event-page__details-place">
                                    {event.venue}
                                  </h4>
                                )}
                                {event.address.address !== "" && (
                                  <p className="event-page__details-address">
                                    {event.address.address}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="event-guests">
                  <div className="d-flex justify-content-between mb-3">
                    {width > 991 && (
                      <p className="event-guests__title">Guests</p>
                    )}
                    <div className="d-flex event-guests__header">
                      {width < 992 && (
                        <p className="event-guests__title">Guests</p>
                      )}
                      <button
                        className="btn btn-dark event-guests__export-btn"
                        onClick={() => {
                          exportToCSV(data_uploaded, "Guests")
                        }}
                        style={{
                          display:
                            window.innerWidth < 992 ? "none" : "inline-block",
                        }}
                      >
                        Export to Excel
                      </button>
                      {window.innerWidth < 992 ? (
                        <GuestsSearch filter={filter} setFilter={setFilter} />
                      ) : !show ? (
                        <button
                          className="d-flex justify-content-center align-items-center event-page__btn"
                          onClick={() => setShow(true)}
                        >
                          <IconSearch />
                        </button>
                      ) : (
                        <GuestsSearch filter={filter} setFilter={setFilter} />
                      )}
                      <div className="event-guests__icon-buttons">
                        <GuestsFilterOpen
                          filter={filter}
                          setFilter={setFilter}
                          checkedFilter={checkedFilter}
                          setCheckedFilter={setCheckedFilter}
                        />
                        <ActionsPopup
                          show={showModalAddInvites}
                          setShow={setShowModalAddInvites}
                          showSendQR={showSendQR}
                          setShowSendQR={setShowSendQR}
                          id={event_id}
                        />
                      </div>
                      <button
                        className="btn btn-dark event-guests__export-btn"
                        onClick={() => {
                          exportToCSV(data_uploaded, "Guests")
                        }}
                        style={{
                          display:
                            window.innerWidth > 991 ? "none" : "inline-block",
                        }}
                      >
                        Export to Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <GuestsListOpen
                filter={filter}
                setFilter={setFilter}
                width={width}
                limit={limit}
                checked={checked}
                setChecked={setChecked}
                allGuestsChecked={allGuestsCheked}
                setAllGuestsChecked={setAllGuestsChecked}
                notify={notify}
              />
            </div>
          </div>
          <DeleteEventModal
            type={"delete"}
            show={showModalDelete}
            setShow={setShowModalDelete}
            title={"Do you really want to delete this event?"}
            notify={notify}
          />
          <VerifyModal show={showVerifyModal} setShow={setShowVerifyModal} />
          <SendModal
            type={"invite"}
            show={showModalSendInvitations}
            setShow={setShowModalSendInvitations}
            title={
              "Invitations will be sent to all guests to whom it was not sent, or with a fail status"
            }
            notify={notify}
          />
          <SendInviteModal
            type={"invite"}
            setChecked={setChecked}
            setAllGuestsChecked={setAllGuestsChecked}
            show={showModalSendInvite}
            setShow={setShowModalSendInvite}
            title={"Your invitation has been sent to the selected guests"}
            notify={notify}
          />
          <AddInvites
            show={showModalAddInvites}
            handleClose={addInvitesClose}
            filter={filter}
            notify={notify}
          />
          <SendQRModal
            type={""}
            show={showSendQR}
            setShow={setShowSendQR}
            title={"Do you really want to send undeliverable QR codes?"}
            buttonRight={""}
            // notify={notify}
          />
          <TestModal
            show={showTestModal}
            setShow={setShowTestModal}
            showNextStep={showNextStep}
            setShowNextStep={setShowNextStep}
            value={phone}
            setValue={setPhone}
            restart={restart}
            notify={notify}
          />
          <EnterCodeModal
            show={showNextStep}
            setShow={setShowNextStep}
            showVerifyModal={showVerifyModal}
            setShowVerifyModal={setShowVerifyModal}
            value={phone}
            setValue={setPhone}
            setShowTestModal={setShowTestModal}
            notify={notify}
            minutes={minutes}
            seconds={seconds}
            restart={restart}
          />
          <AddGuest
            guest={newGuest}
            setGuest={setNewGuest}
            show={addShow}
            handleClose={addPopupClose}
            validation={validation}
            setValidation={setValidation}
            filter={filter}
          />
          <EditGuest
            guest={changeGuest}
            setGuest={setChangeGuest}
            show={editShow}
            setShow={setEditShow}
            validation={validation}
            setValidation={setValidation}
            filter={filter}
            event_id={event_id}
            notify={notify}
          />
          <ImportGuest
            file={fileImport}
            setFile={setFileImport}
            show={showImport}
            handleClose={importPopupClose}
            filter={filter}
          />
          <CopyModal show={showCopy} setShow={setShowCopy} />
        </div>
      )}
      <div className="event-paginator">
        {total_pages !== 0 && (
          <Paginator
            total_pages={total_pages}
            current_page={current_page}
            onPageChanged={onPageChanged}
          />
        )}
      </div>
      <div className="container">
        <button
          type="button"
          className="btn btnloadMore d-lg-none d-sm-flex btn btn-primary event-loadmore"
          onClick={() => {
            dispatch(
              setCurrentPage(
                current_page === total_pages ? 1 : current_page + 1,
              ),
            )
            dispatch(getContactsOnEventMobile(filter))
          }}
        >
          {t("buttons.loadMore")}
        </button>
      </div>
    </>
  )
}

export default EventPageOpen
