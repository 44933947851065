import { useActions } from "@/hooks/useActions"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Cropper from "react-easy-crop"
import getCroppedImg from "./CropImage"

type Props = {}

const CropModal = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspect, setAspect] = useState(1) // Default aspect ratio
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const { toggleCropModalShow, setCroppedImagePath } = useActions()
  const image = useTypeSelector((state) => state.qr.image)
  const { cropModalShow } = useTypeSelector((state) => state.qr)

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onCloseModal = () => {
    toggleCropModalShow()
  }
  const onSave = async () => {
    if (!image) {
      return
    }
    try {
      const croppedImage = await getCroppedImg(image.path, croppedAreaPixels)
      setCroppedImagePath(croppedImage)
      onCloseModal()
    } catch (e) {
      console.error(e)
    }
  }

  // Handle image loading to calculate aspect ratio
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget
    const calculatedAspect = img.naturalWidth / img.naturalHeight
    setAspect(calculatedAspect)
    // Set crop area smaller and centered
    setCrop({
      x: (img.naturalWidth * 0.1) / 2, // 10% smaller width
      y: (img.naturalHeight * 0.1) / 2, // 10% smaller height
    })

    setZoom(1.2) // Slightly zoom in

    console.info("Calculate aspect", calculatedAspect)
  }

  return (
    <Modal
      size="xl"
      onHide={onCloseModal}
      show={cropModalShow}
      className="qr-crop-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height:
            window.innerWidth > 1279
              ? "648px"
              : window.innerWidth > 991
              ? "415px"
              : "150px",
        }}
      >
        {image?.path && aspect && (
          <>
            {/* Hidden image to calculate dimensions */}
            <img
              src={image?.path}
              alt="Crop"
              onLoad={onImageLoad}
              style={{ display: "none" }}
            />
            <Cropper
              image={image?.path}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
              objectFit="contain"
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={onCloseModal} variant="light">
          Cancel
        </Button>
        <Button onClick={onSave} variant="dark">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropModal
